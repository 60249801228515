ion-grid#wait-challenge {
    --ion-grid-width: 40%;
    height: 100%;
    display: grid;
}

#animation {
    width: "250px"
}

#action-btn {
    height: 90%;
}

ion-item.item-disabled {
    opacity: 1;
}